import styles from '@buddieshr/styles/Home.module.scss';
import MetaHeader from '@buddieshr/components/metaHeader';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import Header from '@buddieshr/components/header';
import Footer from '@buddieshr/components/footer';
import Image from 'next/image';
import { Button, Tag } from 'antd';
import WhyCare from '@buddieshr/components/landing/whyCare';
import TextBackground from '@buddieshr/components/text/textBackground';
import TextUnderlined from '@buddieshr/components/text/textUnderlined';
import TrustedBy from '@buddieshr/components/trustedBy';
import Benefits from '@buddieshr/components/benefits';
import G2Logos from '@buddieshr/components/g2Logos';
import { BENEFITS_BUDDIESHR } from '@buddieshr/utils/constants';
import ReviewSnippet from '@buddieshr/components/reviewSnippet';
import Link from 'next/link';
import DreamTeam from '@buddieshr/components/dreamTeam';
import BuddiesHREffect from '@buddieshr/components/landing/buddiesHREffect';
import StopProcrastinating from '@buddieshr/components/landing/stopProcrastinating';
import NotForYouIf from '@buddieshr/components/landing/notForYouIf';
import VisionPanel from '@buddieshr/components/landing/visionPanel';
import LovePanel from '@buddieshr/components/landing/lovePanel';
import PageWrapper from '@buddieshr/components/templates/pageWrapper';

export default function Home({ refSource }) {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <>
      <MetaHeader
        title={t('metaTitle')}
        description={t('descriptionContent')}
        currentURL={`https://buddieshr.com/${
          router.locale === 'en' ? '' : router.locale
        }`}
        locale={router.locale}
      />
      <Header />
      <ReviewSnippet />
      <main className={styles.main}>
        <div className={styles.firstSectionWrapper}>
          <div className={styles.firstSectionInner}>
            <div className={styles.headerSection}>
              <div className={styles.headerSectionInner}>
                <div>
                  {/* <Image
                    src="/img/featuredbyslack.png"
                    width={170}
                    height={84}
                    alt="featured by slack"
                  /> */}
                  <Image
                    src="/img/featuredbyslack_h.png"
                    width={600 / 3}
                    height={178 / 3}
                    alt="featured by slack"
                  />
                </div>
                <h1 className={styles.mainTitle}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    The #1&nbsp;&nbsp;
                    <TextBackground>Employee Engagement</TextBackground>{' '}
                    Software in Slack
                    <Image
                      src="/img/slack_80.png"
                      alt="logo slack"
                      width={26}
                      height={26}
                      style={{ marginLeft: '10px' }}
                    />
                  </div>
                </h1>
                <div className={styles.subtitle}>
                  <div>
                    We help <b>People Teams and Founders</b> build an amazing
                    company culture that <b>attracts and retains top talent</b>
                    <Image
                      src="/img/animated-emojis/Smiling%20Face%20with%20Heart-Eyes.png"
                      alt="Smiling Face with Heart-Eyes"
                      width="28"
                      height="28"
                      style={{ marginLeft: '6px' }}
                    />
                  </div>
                </div>

                <div className={styles.actions}>
                  <Button
                    // icon={<CaretRightOutlined />}
                    type="link"
                    size="large"
                    href="#products"
                  >
                    Discover our Buddies&nbsp;👯
                  </Button>
                  <Link href="/get-started">
                    <Button
                      // icon={<CaretRightOutlined />}
                      type="primary"
                      size="large"
                      style={{ minWidth: 230 }}
                    >
                      Get started
                    </Button>
                  </Link>
                </div>
                <div className={styles.ratedTopPanel}>
                  <G2Logos
                    withTitle={false}
                    withBackground={false}
                    className={styles.g2LogosTop}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <TrustedBy hideInstall />
        <DreamTeam />
        <Benefits
          app={{
            benefits: BENEFITS_BUDDIESHR,
          }}
        />
        <LovePanel showStars onlyFavorites={true} showCompanyLogo />
        <BuddiesHREffect />
        <StopProcrastinating />
        <NotForYouIf />
        <PageWrapper>
          <div className={styles.whyWrapper}>
            <Tag color={'blue'} className={styles.bullshitTag}>
              <span style={{ fontStyle: 'italic' }}>BullShit</span> KPIs
            </Tag>
            <h2>Still not convinced? Look at these numbers!</h2>
            <div>
              In case you’re wondering, these are real KPIs but no-one are
              taking them seriously.
            </div>
            <WhyCare short />
          </div>
        </PageWrapper>
        <div className={`${styles.landingQuote}`}>
          <div className={`${styles.landingQuoteInner}`}>
            Engaged employees are{' '}
            <TextUnderlined>22% more productive</TextUnderlined> at work
          </div>
        </div>
        <VisionPanel />
        <div className={styles.finalCall}>
          <div
          // style={{ fontSize: "144px" }}
          >
            <Image
              src="/img/animated-emojis/Thinking%20Face.png"
              alt="Smiling Face with Tear"
              width="125"
              height="125"
              style={{ marginBottom: 12 }}
            />
          </div>
          <h2>You read all of this and didn’t install any app yet?</h2>
          <div>
            <Link href="/get-started">
              <Button type="primary" size="large" style={{ minWidth: 220 }}>
                Get started now
              </Button>
            </Link>
            <Link target="_blank" href="https://blog.buddieshr.com">
              <Button type="link" size="large" style={{ minWidth: 220 }}>
                Read our Blog
              </Button>
            </Link>
          </div>
        </div>
      </main>
      <Footer refSource={refSource} />
    </>
  );
}
