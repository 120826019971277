import React from "react";
import styles from "@buddieshr/styles/components/notForYouIf.module.scss";
import { Button } from "antd";
import PageWrapper from "../templates/pageWrapper";
import Image from "next/image";

const NotForYouIf = () => (
  <div className={styles.wrapper}>
    <PageWrapper width={800}>
      <div className={styles.stop}>
        {/* ❌ */}
        <Image
          src="/img/animated-emojis/Prohibited.png"
          alt="Prohibited"
          width="185"
          height="185"
        />
      </div>
      <div className={styles.inner}>
        <h2 className={styles.title}>
          <Image
            src="/img/animated-emojis/Face%20Screaming%20in%20Fear.png"
            alt="Face Screaming in Fear"
            width="40"
            height="40"
            style={{ marginRight: 4 }}
          />
          &nbsp;BuddiesHR is NOT for you IF:
        </h2>
        <div className={styles.listWrapper}>
          <ul className={styles.list}>
            <li>You’re convinced that burnout is a sign of dedication</li>
            <li>You don’t want a great culture</li>
            <li>You think employee engagement is bullshit.</li>
            <li>You don’t have Slack. (Booooo!)</li>
            <li>
              You don’t have internet. Wait… how did you see this website then?
            </li>
            <li>
              You prefer high turnover rates because you like meeting new
              people.
            </li>
            <li>
              You love to pay expensive enterprise software promising you 77.43%
              more productivity and save $480,748,123 of retention cost.
            </li>
            <li>
              You prefer thinking about your dream culture and reading this (way
              too long) sentence instead of taking concrete actions.
            </li>
          </ul>
        </div>
        <div className={styles.actions}>
          <Button type="primary" size="large">
            I passed the test, get me started now
          </Button>
        </div>
      </div>
    </PageWrapper>
  </div>
);
export default NotForYouIf;
