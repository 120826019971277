import React from "react";
import styles from "@buddieshr/styles/components/reviewSnippet.module.scss";

const ReviewSnippet = () => (
  <div
    itemProp="itemReviewed"
    itemScope={true}
    itemType="http://schema.org/SoftwareApplication"
  >
    <span
      itemProp="aggregateRating"
      itemScope={true}
      itemType="https://schema.org/AggregateRating"
    >
      <span itemProp="ratingValue" className={styles.hidden}>
        4.9
      </span>
      <span itemProp="ratingCount" className={styles.hidden}>
        260
      </span>
    </span>
    <div className={styles.hidden} itemProp="name">
      BuddiesHR
    </div>
    <span itemProp="operatingSystem" className={styles.hidden}>
      Slack
    </span>
    {/* <div
      itemProp="reviewRating"
      itemScope={true}
      itemType="https://schema.org/Rating"
    >
      <div itemProp="ratingValue" className={styles.hidden}>
        5
      </div>
      <span itemProp="ratingCount" value={112}></span>
    </div> */}
  </div>
);

export default ReviewSnippet;
