import { motion } from "framer-motion";

const AnimateOnShow = ({ children }) => (
  <motion.div
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
    transition={{ delay: 0.3, duration: 0.7 }}
    variants={{
      visible: { opacity: 1, scale: 1, y: 0 },
      hidden: { opacity: 0, scale: 0.95, y: 10 },
    }}
  >
    {children}
  </motion.div>
);

export default AnimateOnShow;
