import React from "react";
import styles from "@buddieshr/styles/components/whycare.module.scss";
import presets from "@buddieshr/styles/presets.module.scss";
import TextUnderlined from "../text/textUnderlined";

const WhyCare = ({ short = false }) => (
  <div className={styles.wrapperSection}>
    {!short && (
      <div className={styles.titleFlex}>
        <div className={styles.titleFlex1}>
          <h2>Why should I care about employee engagement?</h2>
        </div>
        <div className={styles.titleFlex2}>
          Attracting, recruiting, and retaining good employees isn’t easy. With
          a distributed or remote workforce, it’s even more challenging.
          <br />
          <br />
          Based on decades of employee engagement research,{" "}
          <TextUnderlined>Gallup</TextUnderlined> knows that engaged employees
          produce better business outcomes than other employees.
        </div>
      </div>
    )}

    <div className={styles.wrapper}>
      <div className={styles.element}>
        <div className={styles.number}>42%</div>
        <div className={styles.tag}>TURNOVER</div>
        <div className={styles.barre}></div>
        <div className={styles.content}>
          Actively disengaged employees are 42% more likely to be actively
          looking or watching for openings in comparison to engaged employees. (
          <a
            href="https://www.gallup.com/workplace/506798/globally-employees-engaged-stressed.aspx"
            style={{ color: "#0015ef", textDecoration: "underline" }}
          >
            1
          </a>
          )
        </div>
      </div>
      <div className={styles.element}>
        <div className={styles.number}>81%</div>
        <div className={styles.tag}>ABSENTEISM</div>
        <div className={styles.barre}></div>
        <div className={styles.content}>
          81% of the time, absenteism can be avoided by increasing the employee
          engagement. (
          <a
            href="https://www.gallup.com/workplace/285674/improve-employee-engagement-workplace.aspx"
            style={{ color: "#0015ef", textDecoration: "underline" }}
          >
            2
          </a>
          )
        </div>
      </div>
      <div className={styles.element}>
        <div className={styles.number}>18%</div>
        <div className={styles.tag}>LESS PRODUCTIVITY</div>
        <div className={styles.barre}></div>
        <div className={styles.content}>
          Disengaged employees have a 18% decrease in employee productivity (
          <a
            href="https://www.gallup.com/workplace/285674/improve-employee-engagement-workplace.aspx"
            style={{ color: "#0015ef", textDecoration: "underline" }}
          >
            3
          </a>
          )
        </div>
      </div>
    </div>

    {/* <div>It pays to invest in your people</div> */}
  </div>
);

export default WhyCare;
