import styles from "@buddieshr/styles/components/textBackground.module.scss";

const TextBackground = ({ children, className, revert = false }) => (
  <span
    className={`${styles.text}${className ? ` ${className}` : ""}${
      revert ? ` ${styles.revert}` : ""
    }`}
  >
    {children}
  </span>
);

export default TextBackground;
